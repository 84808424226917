/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
// import Router from 'next/router'

import { APP_DOMAIN } from '@app/constants';

import Cookies from 'js-cookie';
import { Router } from '../config/i18n';
import { isOrganiser } from './authorization';
import { isAbsoluteURL } from './strings';

const { routes } = require('../server/routes');



const parseRouteUrl = (route, params) => {
  for (const key in params) {
    const regex = new RegExp(`:${key}`,"g");
    route = route.replace(regex, params[key]);
  }
  return route;
  
}

export const generateLink = (pathname, query) => {
  let queryParams = '';
  if (query) {
    const queryArr = Object.entries(query);
    queryArr.forEach((item, index) => {
      queryParams = `${queryParams}${item[0]}=${item[1]}${
        index !== queryArr.length - 1 ? '&' : ''
      }`;
    });
  }

  return `${pathname}${queryParams !== '' ? `?${queryParams}` : ''}`;
};

export const redirect = (context, target, params) => {
  if (context.res) {
    // server
    // 303: "See other"
    context.res.writeHead(303, { Location: target });
    context.res.end();
  } else {
    // In the browser, we just pretend like this never even happened ;)
    if (!Object.prototype.hasOwnProperty.call(routes, target)) {
      Router.push(target);
      return;
    }
    const routeDetails = routes[target];
    if (params) {
      Router.push(
        {
          pathname: routeDetails.component,
          query: { ...params },
        },
        generateLink(routeDetails.url, { ...params })
      );
    } else {
      Router.push(routeDetails.component, generateLink(routeDetails.url, { ...params }));
    }
  }
};

/**
 * This function is used to replace Link params with the params object
 * @param {*} route
 * @param {*} params
 * @returns
 */
export const getUrlFromRoute = (routeName, config) => {
  const { 
    params = {},
    query = null, 
    absolute = true, 
    locale = 'en'
  } = config || {};

  let url = routeName;
  if (Object.prototype.hasOwnProperty.call(routes, routeName)) {
    const routeDetails = routes[routeName];
    // In the browser, we just pretend like this never even happened ;)
    url = params ? parseRouteUrl(routeDetails.url, params) : routeDetails.url;
    if (query) {
      const qs = new URLSearchParams(query).toString();
      url += `?${qs}`;
    }  
  }
  if (absolute && !isAbsoluteURL(url)) {
    url = `${APP_DOMAIN}/${locale}${url}`
  }
  return url;
};

const getRouteDetails = (route) => {
  const routeParts = route.split('.');
  let routeDetails = routes;

  if (!routeDetails || !Object.prototype.hasOwnProperty.call(routeDetails, routeParts[0])) {
    console.error(`Route "${route}" does not exist.`);
    return null;
  }
  routeDetails = routeDetails[routeParts[0]];

  if (routeDetails.routes) {
    for (const childRoute of routeDetails.routes) {
      if (childRoute.name === routeParts[1]) {
        return childRoute;
      }
    }
  }

  return routeDetails;
};


export const redirectToRoute = (context, route, { params = {}, query } = {}, options) => {
  const routeDetails = getRouteDetails(route);

  if (!routeDetails) {
    return false;
  }
  // In the browser, we just pretend like this never even happened ;)
  let url = params ? parseRouteUrl(routeDetails.url, params) : routeDetails.url;
  if (query) {
    const qs = new URLSearchParams(query).toString();
    url += `?${qs}`;
  }

  if (context?.res) {
    // server
    // 303: "See other"
    context.res.writeHead(303, { Location: url });
    context.res.end();
    return false;
  }
  Router.push(
    {
      pathname: routeDetails.component,
      query: { ...params, ...query },
    },
    url,
    options
  );

  return true;
};

function setIntendedRoute(query, route) {
  const { lng, subpath, ...params } = query || {};
  return JSON.stringify({ name: route, params });
}

export const goToLogin = (context, nextRoute = "/login") => {
  const intended = setIntendedRoute(context.query, nextRoute);
  if (context && context.res) {
    // server
    // 303: "See other"

    context.res.writeHead(303, { Location: `${routes.login.url}?next=${intended}` });
    // context.res.writeHead(303, { Location: routes.login.url });
    context.res.end();
    return;
  }

  redirectToRoute({}, 'login', { query: { next: intended } });
};

export const goToDashboard = (context, user) => {
  const redirectUrl = Cookies.get('preLoginUrl');
  // If redirectUrl is provided, use it
  if (redirectUrl) {
    const route = { url: redirectUrl };

    // Handle server-side redirection
    if (context && context.res) {
      context.res.writeHead(303, { Location: route.url });
      context.res.end();
      return;
    }

    Router.push(route.url);
    Cookies.remove('preLoginUrl');
  } else {
    const route = user && isOrganiser(user) ? routes.my_opportunities : routes.opportunity_search;

      if (context && context.res) {
        context.res.writeHead(303, { Location: route.url });
        context.res.end();
        return;
      }
      Router.push(
        {
          pathname: route.component,
        },
        route.url
      );
  }
};

export const intended = ({ next = null } = {}, defaultRoute) => {
  if (!next) {
    Router.push(defaultRoute ? routes[defaultRoute].url : '/');
  }
  try {
    const routeInfo = JSON.parse(next);
    redirectToRoute({}, routeInfo.name, { params: routeInfo.params });
  } catch (err) {
    Router.push(defaultRoute ? routes[defaultRoute].url : '/');
  }
};
